var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.visible,
        closable: false,
        footer: null,
        title: null,
        width: 400
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "font-weight": "500",
            margin: "0 0 30px 0",
            "font-size": "1rem"
          }
        },
        [
          _c("a-icon", {
            staticStyle: { color: "#faad14", "font-size": "1.2rem" },
            attrs: { type: "question-circle" }
          }),
          _c("span", { staticClass: "ml-xs" }, [
            _vm._v(_vm._s(_vm.$t("Duplicate Project")))
          ])
        ],
        1
      ),
      _c(
        "a-row",
        {
          staticClass: "mb-md",
          attrs: { gutter: [0, 32], type: "flex", align: "middle" }
        },
        [
          _c(
            "a-checkbox",
            {
              attrs: { checked: _vm.isDuplicatePages },
              on: { change: _vm.toggleDuplicatePages }
            },
            [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("Include all page inside the project?")) +
                    " "
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "a-row",
        {
          staticClass: "mb-md",
          attrs: { gutter: [0, 32], type: "flex", align: "middle" }
        },
        [
          _c(
            "a-checkbox",
            {
              attrs: {
                checked: _vm.isDuplicateCodes,
                disabled: !_vm.isDuplicatePages
              },
              on: { change: _vm.toggleDuplicateCodes }
            },
            [
              _c("span", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "Include all codes inside each page of the project?"
                      )
                    ) +
                    " "
                )
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "a-row",
        {
          attrs: {
            gutter: [8, 16],
            type: "flex",
            justify: "end",
            align: "middle"
          }
        },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 7 } },
            [
              _c(
                "a-button",
                {
                  staticStyle: { width: "100%" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 7 } },
            [
              _c(
                "a-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "primary" },
                  on: { click: _vm.handleDuplicate }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Yes")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }