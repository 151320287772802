//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    project: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isDuplicatePages: false,
      isDuplicateCodes: false
    };
  },

  methods: {
    toggleDuplicateCodes({ target }) {
      this.isDuplicateCodes = target.checked;
    },
    toggleDuplicatePages({ target }) {
      this.isDuplicatePages = target.checked;
      if (!target.checked) this.isDuplicateCodes = false;
    },
    handleDuplicate() {
      this.$emit('ok', this.project.id, {
        isDuplicatePages: this.isDuplicatePages,
        isDuplicateCodes: this.isDuplicateCodes
      });
    }
  }
};
