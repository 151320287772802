//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  components: {},

  props: {
    rowData: { type: Array, require: true, default: () => [] },
    pagination: { type: Object, require: true, default: () => ({}) },
    selectedProjects: { type: Array, require: true, default: () => [] }
  },

  data() {
    return {
      loading: false,
      projects: [],
      selectedProject: null,
      chooseProjectModalVisible: false
    };
  },

  computed: {
    ...mapGetters('user', ['userInfo']),

    isSelectedProject() {
      return this.selectedProjects.length > 0;
    }
  },
  watch: {
    rowData: {
      handler(newValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        this.projects = newValue;
      },
      immediate: true
    }
  },
  mounted() {},

  methods: {
    ...mapMutations('project', ['UPDATE_ORDER']),
    ...mapActions('project', ['loadProjects', 'reset']),
    handleSelectProject({ target }, project) {
      let newSelectedProjects = [...this.selectedProjects];
      if (target.checked) {
        newSelectedProjects.push(project);
      } else {
        newSelectedProjects = newSelectedProjects.filter(item => item.id !== project.id);
      }
      this.$emit('onSeleted', { data: newSelectedProjects }, 'card-view');
    },
    handlePageChange(page, pageSize) {
      this.$emit('onPageChange', { page, pageSize });
    },
    handleGoTo(path) {
      this.$router.push(path);
    },
    pathToEdit(project) {
      return `/projects/${project.id}`;
    },
    pathToPages(project) {
      return `/projects/${project.id}/pages`;
    },
    pathToArchivePages(project) {
      return `/projects/${project.id}/archived-pages`;
    },
    subDomainUrl(project) {
      const baseSubDomain = process.env.VUE_APP_UNIVOICE_SUBDOMAIN;
      return `https://${project?.sub_domain}${baseSubDomain}`;
    }
  }
};
