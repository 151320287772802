var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    { attrs: { spinning: _vm.loading } },
    [
      _vm.projects.length
        ? _c(
            "a-row",
            { staticClass: "card-view", attrs: { gutter: 26 } },
            _vm._l(_vm.projects, function(project, index) {
              return _c(
                "a-col",
                {
                  key: index,
                  staticClass: "pb-md",
                  attrs: { xs: 24, md: 12, xl: 8, xxl: 6 }
                },
                [
                  _c(
                    "a-card",
                    {
                      staticClass: "card-item",
                      attrs: {
                        bodyStyle: {
                          padding: "12px 12px 4px"
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { id: "checkbox" } },
                        [
                          _c("a-checkbox", {
                            attrs: {
                              checked: _vm.selectedProjects.find(function(
                                item
                              ) {
                                return item.id == project.id
                              })
                                ? true
                                : false
                            },
                            on: {
                              change: function(e) {
                                return _vm.handleSelectProject(e, project)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "visualize-area" }, [
                        _c(
                          "div",
                          {
                            staticClass: "thumbnail",
                            attrs: { role: "button" },
                            on: {
                              click: function($event) {
                                _vm.handleGoTo(_vm.pathToPages(project))
                              }
                            }
                          },
                          [
                            project.thumbnail && project.thumbnail.url
                              ? _c("img", {
                                  attrs: {
                                    src: project.thumbnail.url,
                                    alt: project.thumbnail.alt
                                  }
                                })
                              : _c("img", {
                                  attrs: {
                                    src: require("@/assets/gray-background.png"),
                                    alt: "gray bg"
                                  }
                                })
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "state" },
                          [
                            project.pinned
                              ? _c(
                                  "a-tag",
                                  { attrs: { color: "#2e8bce" } },
                                  [
                                    _c("a-icon", {
                                      staticClass: "tag",
                                      attrs: { type: "pushpin" }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]),
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 8] } },
                        [
                          _c("a-col", { attrs: { span: 24 } }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(" " + _vm._s(project.name) + " ")
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 8] } },
                        [
                          _c(
                            "a-col",
                            {
                              staticStyle: { display: "flex" },
                              attrs: { xs: 24 }
                            },
                            [
                              _c("span", { staticClass: "mr-sm" }, [
                                _vm._v(_vm._s(_vm.$t("project.grid.URL")) + ":")
                              ]),
                              _c(
                                "a",
                                {
                                  staticClass: "text-ellipsis",
                                  attrs: {
                                    href: _vm.subDomainUrl(project),
                                    target: "_blank"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.subDomainUrl(project)) +
                                      " "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-row",
                        { attrs: { gutter: [16, 8] } },
                        [
                          _c("a-col", { attrs: { xs: 20 } }, [
                            _c("span", { staticClass: "mr-sm" }, [
                              _vm._v(_vm._s(_vm.$t("Created")) + ":")
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dayjs")(
                                    project.created,
                                    "YYYY/MM/DD HH:mm:ss"
                                  )
                                )
                              )
                            ])
                          ]),
                          _c(
                            "a-col",
                            { staticClass: "text-right", attrs: { xs: 4 } },
                            [
                              _c(
                                "a-tag",
                                {
                                  staticStyle: { margin: "0" },
                                  attrs: {
                                    color: project.is_public ? "green" : "red"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          project.is_public
                                            ? "Public"
                                            : "Private"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "template",
                        { slot: "actions" },
                        [
                          _c(
                            "span",
                            {
                              on: {
                                click: function($event) {
                                  _vm.handleGoTo(_vm.pathToEdit(project))
                                }
                              }
                            },
                            [
                              _c("a-icon", {
                                staticClass: "mr-xs",
                                attrs: { type: "edit" }
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("Edit")) + " ")
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              on: {
                                click: function($event) {
                                  _vm.handleGoTo(_vm.pathToPages(project))
                                }
                              }
                            },
                            [
                              _c("a-icon", {
                                staticClass: "mr-xs",
                                attrs: { type: "container" }
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("Page list")) + " ")
                            ],
                            1
                          ),
                          _c(
                            "a-dropdown",
                            [
                              _c("a-icon", { attrs: { type: "ellipsis" } }),
                              _c(
                                "a-menu",
                                { attrs: { slot: "overlay" }, slot: "overlay" },
                                [
                                  _c(
                                    "a-menu-item",
                                    {
                                      key: "Archived page",
                                      on: {
                                        click: function($event) {
                                          _vm.handleGoTo(
                                            _vm.pathToArchivePages(project)
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("a-icon", {
                                        staticClass: "mr-xs",
                                        attrs: { type: "inbox" }
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("Archived page list"))
                                        )
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-menu-item",
                                    {
                                      key: "Duplicate",
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "onDuplicate",
                                            project
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("a-icon", { attrs: { type: "copy" } }),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("Duplicate")))
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-menu-item",
                                    {
                                      key: "Public/Private",
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit(
                                            "onOpenChangeStatusModel",
                                            project
                                          )
                                        }
                                      }
                                    },
                                    [
                                      !project.is_public
                                        ? [
                                            _c("a-icon", {
                                              attrs: { type: "unlock" }
                                            }),
                                            _c("span", [
                                              _vm._v(_vm._s(_vm.$t("Public")))
                                            ])
                                          ]
                                        : _vm._e(),
                                      project.is_public
                                        ? [
                                            _c("a-icon", {
                                              attrs: { type: "lock" }
                                            }),
                                            _c("span", [
                                              _vm._v(_vm._s(_vm.$t("Private")))
                                            ])
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  ),
                                  _c(
                                    "a-menu-item",
                                    {
                                      key: "PinToTop",
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit("onPinning", project)
                                        }
                                      }
                                    },
                                    [
                                      _c("a-icon", {
                                        attrs: { type: "pushpin" }
                                      }),
                                      !project.pinned
                                        ? _c("span", [
                                            _vm._v(_vm._s(_vm.$t("Pin to top")))
                                          ])
                                        : _c("span", [
                                            _vm._v(_vm._s(_vm.$t("Unpin")))
                                          ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-menu-item",
                                    {
                                      key: "Export",
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit("onExport", project)
                                        }
                                      }
                                    },
                                    [
                                      _c("a-icon", {
                                        attrs: { type: "export" }
                                      }),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("Export project")))
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            }),
            1
          )
        : _c("p", { staticClass: "mt-md text-center" }, [
            _vm._v(" " + _vm._s(_vm.$t("No data")) + " ")
          ]),
      _c(
        "div",
        { staticStyle: { "margin-top": "16px", "text-align": "right" } },
        [
          _c("a-pagination", {
            attrs: {
              current: _vm.pagination.current,
              "show-size-changer": "",
              "page-size-options": ["10", "20", "24", "30", "40", "50"],
              "page-size": _vm.pagination.pageSize,
              total: _vm.pagination.total,
              "show-total": function(total) {
                return _vm.$t("Total") + " " + total
              }
            },
            on: {
              "update:pageSize": function($event) {
                return _vm.$set(_vm.pagination, "pageSize", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.pagination, "pageSize", $event)
              },
              change: _vm.handlePageChange,
              showSizeChange: _vm.handlePageChange
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }