var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "page-header",
        { staticClass: "mb-xs", attrs: { title: _vm.$t("Project list") } },
        [
          _c(
            "template",
            { slot: "extra" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", icon: "plus" },
                  on: { click: _vm.goToProjectCompose }
                },
                [_vm._v(_vm._s(_vm.$t("Project compose")))]
              ),
              _c(
                "a-button",
                {
                  staticClass: "ml-xs",
                  attrs: { icon: "import" },
                  on: { click: _vm.openProjectImport }
                },
                [_vm._v(_vm._s(_vm.$t("Import project")))]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "search-box",
        {
          staticClass: "pb-sm",
          attrs: { queryParam: _vm.queryParam },
          on: {
            changeTitle: _vm.changeTitle,
            changeSubDomain: _vm.changeSubDomain,
            handleSearch: _vm.handleSearch,
            handleReset: _vm.handleReset
          }
        },
        [
          _c(
            "template",
            { slot: "left" },
            [
              _c(
                "a-button",
                {
                  staticClass: "mr-xs",
                  attrs: { icon: "inbox", disabled: !_vm.isSelectedProject },
                  on: { click: _vm.openArchiveModal }
                },
                [_vm._v(_vm._s(_vm.$t("Archive")))]
              ),
              _c(
                "a-button",
                {
                  attrs: { icon: "eye", disabled: !_vm.isSelectedProject },
                  on: {
                    click: function($event) {
                      _vm.publicSettingModalVisible = true
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Switch public/private")))]
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "right" },
            [
              _c(
                "a-button",
                { on: { click: _vm.onChangeView } },
                [
                  _c("a-icon", {
                    staticStyle: { color: "#1890ff" },
                    attrs: {
                      type: _vm.queryParam.mode === "card" ? "appstore" : "menu"
                    }
                  }),
                  _c("span", [_vm._v(" " + _vm._s(_vm.$t("ChangeView")))])
                ],
                1
              )
            ],
            1
          )
        ],
        2
      ),
      _c("a-spin", { attrs: { spinning: _vm.loading } }, [
        _c(
          "div",
          { staticClass: "spin-content" },
          [
            _vm.queryParam.mode === "card"
              ? _c("CardView", {
                  attrs: {
                    rowData: _vm.projects,
                    pagination: _vm.pagination,
                    selectedProjects: _vm.selectedProjects
                  },
                  on: {
                    onPageChange: _vm.handleChangePage,
                    onDuplicate: _vm.onOpenDuplicateProjectModalFromChild,
                    onOpenChangeStatusModel:
                      _vm.onOpenChangeStatusModelFromChild,
                    onPinning: _vm.handlePinToTopFromChild,
                    onExport: _vm.handleProjectExportFromChild,
                    onSeleted: _vm.handleSelectProject,
                    onReload: _vm.getData
                  }
                })
              : _vm.queryParam.mode === "grid"
              ? _c("SGrid", {
                  ref: "sGrid",
                  attrs: {
                    "grid-name": "project",
                    rowData: _vm.projects,
                    enableCheckbox: true,
                    columnDefs: _vm.columnDefs,
                    gridContext: _vm.context,
                    pagination: _vm.pagination,
                    getRowClass: _vm.getRowClass,
                    rowHeight: 124
                  },
                  on: {
                    onGridReady: _vm.handleGridReady,
                    onPageChange: _vm.handleChangePage,
                    selectionChanged: _vm.handleSelectProject
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _vm.projectArchiveVisible
        ? _c("ArchiveModal", {
            attrs: {
              visible: _vm.projectArchiveVisible,
              "row-data": _vm.selectedProjects
            },
            on: {
              cancel: function() {
                _vm.projectArchiveVisible = false
              },
              ok: _vm.handleProjectArchiving
            }
          })
        : _vm._e(),
      _vm.projectImportVisible
        ? _c("ImportModal", {
            attrs: {
              visible: _vm.projectImportVisible,
              title: "Import project",
              type: "project"
            },
            on: {
              cancel: function() {
                _vm.projectImportVisible = false
              },
              ok: _vm.handleProjectImporting
            }
          })
        : _vm._e(),
      _vm.publicSettingModalVisible
        ? _c(
            "PublicSettingModal",
            {
              attrs: {
                visible: _vm.publicSettingModalVisible,
                loading: _vm.loading
              },
              on: {
                cancel: function($event) {
                  ;(_vm.publicSettingModalVisible = false),
                    (_vm.checkedModalPublic = false)
                },
                ok: _vm.updatePublicSetting
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "content" }, slot: "content" },
                [
                  _c(
                    "a-row",
                    {
                      staticClass: "mt-sm",
                      attrs: { type: "flex", align: "middle" }
                    },
                    [
                      _c(
                        "span",
                        { staticStyle: { color: "#eca30c" } },
                        [
                          _c("a-icon", { attrs: { type: "info-circle" } }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "This action will change the visibility of all pages in selected projects. Are you sure you want to continue?"
                                )
                              ) +
                              " "
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _c("DuplicateModal", {
        attrs: {
          visible: _vm.duplicateModalVisible,
          project: _vm.interactedProject
        },
        on: {
          cancel: function($event) {
            _vm.duplicateModalVisible = false
          },
          ok: _vm.handleDuplicateProject
        }
      }),
      _c("ChangeStatusModal", {
        attrs: {
          visible: _vm.changeStatusModalVisible,
          project: _vm.interactedProject
        },
        on: {
          cancel: function($event) {
            _vm.changeStatusModalVisible = false
          },
          ok: _vm.handleChangeStatusFromChild
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }