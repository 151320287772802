//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    project: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isIncludeCodes: false
    };
  },

  computed: {
    isPublic() {
      return this.project?.is_public || false;
    },
    modalTitle() {
      return this.$t(
        `Do you want to change the status of this project to ${
          this.isPublic ? 'private' : 'public'
        }?`
      );
    }
  },

  methods: {
    handleChangeStatus() {
      const includes = ['page'];
      const isChangePublic = !this.isPublic;
      this.$emit('ok', { id: this.project.id, payload: { isPublic: isChangePublic, includes } });
    }
  }
};
