var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.visible,
        closable: false,
        footer: null,
        title: null,
        width: 400
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "mb-sm font-bold",
          staticStyle: { "font-size": "1rem" }
        },
        [
          _c("a-icon", {
            staticStyle: { color: "#faad14", "font-size": "1.2rem" },
            attrs: { type: "question-circle" }
          }),
          _c("span", { staticClass: "ml-xs font-bold" }, [
            _vm._v(_vm._s(_vm.modalTitle))
          ])
        ],
        1
      ),
      _c(
        "a-row",
        { staticClass: "mb-sm", attrs: { type: "flex", align: "middle" } },
        [
          _c(
            "span",
            { staticClass: "pl-sm mb-sm", staticStyle: { color: "#eca30c" } },
            [
              _c("a-icon", { attrs: { type: "info-circle" } }),
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "This action will change the visibility of all pages in this project. Are you sure you want to continue?"
                    )
                  ) +
                  " "
              )
            ],
            1
          )
        ]
      ),
      _c(
        "a-row",
        {
          attrs: {
            gutter: [8, 32],
            type: "flex",
            justify: "end",
            align: "middle"
          }
        },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 7 } },
            [
              _c(
                "a-button",
                {
                  staticStyle: { width: "100%" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 7 } },
            [
              _c(
                "a-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: { type: "primary" },
                  on: { click: _vm.handleChangeStatus }
                },
                [_vm._v(_vm._s(_vm.$t("Yes")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }