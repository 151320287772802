//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    queryParam: {
      type: Object,
      required: true
    }
  },

  computed: {
    acitveClearButton() {
      const { title, sub_domain } = this.queryParam;
      return title || sub_domain;
    }
  },

  methods: {
    changeTitle(value) {
      this.$emit('changeTitle', value);
      this.handleSearch();
    },
    changeSubDomain(value) {
      this.$emit('changeSubDomain', value);
      this.handleSearch();
    },
    handleSearch() {
      this.$emit('handleSearch');
    },
    handleReset() {
      this.$emit('handleReset');
    }
  }
};
